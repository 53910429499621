import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #333333;
  text-align: ${props => props.textAlign || ""};
  @media screen and (max-width: 768px) {
    font-size: 1.85rem;
  }
`
export const SubTitle = styled.h2`
  font-size: 1.5rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    font-weight: 600;
  }
`
export const Columns = styled.div`
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
  &:last-child {
    margin-bottom: -0.75rem;
  }
  &:not(:last-child) {
    margin-bottom: calc(1.5rem - 0.75rem);
  }
  @media screen and (min-width: 1024px) {
    display: flex;
  }
`

export const Column = styled.div`
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
  max-width: ${props => props.maxWidth || "100%"};
  @media screen and (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
  }
  & div:first-child {
    margin: ${props => props.ChildMargin || ""};
  }
`

export const IconButton = styled.button`
  background: ${props => props.background || "transparent"};
  border: 0;
  color: ${props => props.color || "#363636"};
  font-size: ${props => props.fontSize || "1rem"};
  cursor: pointer;
  outline: 0;
  &:focus {
    outline: 0;
  }
`

export const Button = styled.button`
  align-items: center;
  border: ${props => props.border || "1px solid transparent"};
  border-radius: ${props => props.borderRadius || "4px"};
  box-shadow: none;
  display: inline-flex;
  font-size: ${props => props.fontSize || "1rem"};
  height: 2.5em;
  position: relative;
  border-width: 1px;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
  border-color: ${props => props.borderColor || "#dbdbdb"};
  border-left-color: ${props => props.borderLeftColor || ""};
  color: ${props => props.color || "#363636"};
  background-color: ${props => props.backgroundColor || "transparent"};
  margin: ${props => props.margin || "0 7px"};
  &:hover {
    border-color: ${props => props.hoverBorderColor || "#b5b5b5"};
    background-color: ${props => props.hoverBackgroundColor || "#f7f9fa"};
    color: ${props => props.hoverColor || "#363636"};
  }
  &:focus,
  &.focus {
    outline: 0;
  }
  &.disabled,
  &:disabled {
    background-color: ${props => props.disbledBackgoundColor || "whitesmoke"};
    cursor: not-allowed;
    box-shadow: none;
    color: ${props => props.disabledColor || "#7a7a7a"};
  }
  &.staticButton {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  &.staticRight {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
`

export const Input = styled.input`
  align-items: center;
  border: ${props => props.border || "1px solid transparent"};
  box-shadow: none;
  display: inline-flex;
  font-size: ${props => props.fontSize || "1rem"};
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;
  background-color: white;
  border-color: ${props => props.borderColor || "#dbdbdb"};
  border-radius: ${props => props.radius || "4px"};
  color: #363636;
  max-width: ${props => props.maxWidth || "100%"};
  width: ${props => props.width || "100%"};
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  &::-moz-placeholder {
    color: rgba(54, 54, 54, 0.3);
  }
  &:focus,
  &.focus {
    outline: none;
  }

  &.disabled,
  &:disabled {
    background-color: whitesmoke;
    border-color: #dbdbdb;
    box-shadow: none;
    color: #7a7a7a;
  }
  &[readonly] {
    box-shadow: none;
  }
  &.borderedRight {
    border-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  &.borderedLeft {
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  @media screen and (max-width: 768px) {
    font-size: ${props => props.fontSizeMobile || "1rem"};
  }
  &.makeTextCentered {
    text-align: center;
  }
  &:not(:last-child) {
    margin-bottom: ${props => props.marginBottomLastChild || "0.75rem"};
  }
`

export const Textarea = styled.textarea`
  align-items: center;
  border: 1px solid transparent;
  box-shadow: none;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #363636;
  margin-bottom: 0.75rem;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: calc(0.75em - 1px);
  resize: none;
  &:not([rows]) {
    max-height: 40em;
    min-height: 8em;
  }
  &:focus,
  &.focus {
    outline: none;
  }
  &.disabled,
  &:disabled {
    background-color: whitesmoke;
    border-color: whitesmoke;
    box-shadow: none;
    color: #7a7a7a;
  }
  &[readonly] {
    box-shadow: none;
  }
`

export const StaticButton = styled.p`
  margin: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  position: relative;
  border-width: 1px;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
  background-color: ${props => props.background || "whitesmoke"};
  border-color: ${props => props.borderColor || "#dbdbdb"};
  color: ${props => props.color || "#7a7a7a"};
  box-shadow: none;
  pointer-events: none;
  align-items: center;
  border-bottom-right-radius: ${props => props.bottomRightRadius || 0};
  border-top-right-radius: ${props => props.topRightRadius || 0};
  border-bottom-left-radius: ${props => props.bottomLeftRadius || "4px"};
  border-top-left-radius: ${props => props.TopLeftRadius || "4px"};
`

export const Field = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent || "flex-start"};
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  &.marginLeft {
    margin-left: 7px;
  }
`
export const Control = styled.div`
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: inherit;
  &:not(:last-child) {
    margin-right: -1px;
  }
  &.expandedWidth {
    flex-grow: 1;
    flex-shrink: 1;
    & select.staticSelect {
      width: 100% !important;
    }
  }
`
export const DropdownMenu = styled.div`
  display: none;
  left: 0;
  min-width: 12rem;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  z-index: 20;
  &:hover {
    display: block !important;
  }
`
export const Dropdown = styled.div`
  display: inline-flex;
  position: relative;
  vertical-align: top;
  &:hover ${DropdownMenu} {
    display: block !important;
  }
`

export const DropdownContent = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`
export const DropdownItemLink = styled(props => <Link {...props} />)`
  color: #4a4a4a;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;

  padding-right: 3rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  &:hover {
    background-color: whitesmoke;
    color: #0a0a0a;
  }
  &.activeL {
    background-color: #3273dc;
    color: #fff;
  }
`

export const Icon = styled.span`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
`
export const Span = styled.span`
  color: #6c757d;
`

export const Select = styled.div`
  &::after {
    border: 3px solid black;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 0.625em;
    margin-top: -0.4375em;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 0.625em;
    right: 1.125em;
    z-index: 4;
  }
  & select {
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(2.75em - 1px);
    padding-top: calc(0.5em - 1px);
    position: relative;
    vertical-align: top;
    width: ${props => props.width || "100%"};
    &:focus {
      outline: none;
    }
    &:hover {
      border-color: #b5b5b5;
    }
    background-color: white;
    border-color: #dbdbdb;
    border-radius: ${props => props.borderRadius || "4px"};
    color: #363636;
    &[disabled] {
      background-color: whitesmoke;
      cursor: not-allowed;
      box-shadow: none;
      color: #7a7a7a;
    }
  }
  display: inline-block;
  width: 100%;
  max-width: ${props => props.maxWidth || "100%"};
  position: relative;
  vertical-align: top;
  margin-bottom: 4px;
  &::after {
    right: 1.125em;
    z-index: 4;
  }
  &select {
    cursor: pointer;
    display: block;
    font-size: 1em;
    outline: none;
    &::-ms-expand {
      display: none;
    }
  }
  & .staticSelect {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width: max-content;
  }
`

export const SpanVerticalAlign = styled.span`
  vertical-align: ${props => props.verticalAlign || "-webkit-baseline-middle"};
`
export const MessageParagraph = styled.p`
  color: ${props => props.color || "black"};
  font-size: 1.5rem;
`

export const Card = styled.div`
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  max-width: ${props => props.maxWidth || "100%"};
  overflow: hidden;
  position: relative;
`
export const CardContent = styled.div`
  background-color: transparent;
  padding: 1.5rem;
`
export const CardFooter = styled.div`
  background-color: transparent;
  border-top: 1px solid #ededed;
  align-items: stretch;
  display: flex;
  & ${Button} {
    align-items: center;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    padding: 0.75rem;
  }
`

export const LoadingAnimation = styled.div`
  margin: auto;
  padding: ${props => props.padding || "0.75rem"};
  display: block;
  width: ${props => props.width || "50px"};
  height: ${props => props.height || "50px"};
  border: ${props => props.border || "5px solid #1da1f2"}; ;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-animation: spin 600ms infinite linear;
  -moz-animation: spin 600ms infinite linear;
  -o-animation: spin 600ms infinite linear;
  -ms-animation: spin 600ms infinite linear;
  animation: spin 600ms infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }

`

export const ButtonSignTrue = styled.span`
  position: absolute;
  top: -10px;
  right: -20px;
  padding: 5px 15px;
  border-radius: 50%;
  color: red;
  font-size: 2em;
  display: none;
`

export const BoxShadowWindow = styled.div`
  margin-top: 1rem;
  background-color: white;
  border-radius: 6px;
  display: block;
  padding: 1.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
`
export const AngelRotation = styled.i`
  float: right;
  transform: ${props => props.transform || ""};
  font-weight: 600;
`
export const ArrowDown = styled.span`
  height: 0.5em;
  width: 0.5em;
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-bottom: 3px;
  border-color: ${props => props.bordervColor || "black"};
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`
